<template>
  <div>
    <b-row>
      <b-col>
        <div class="h3 mb-2">
          Choose your plan! (Cancel anytime.)
        </div>
      </b-col>
    </b-row>
    <b-row
      v-if="plans"
      class="pricing-card"
    >
      <b-col md="12">
        <b-row>
          <b-col
            v-for="plan in plans"
            :key="plan.id"
            md="4"
          >
            <b-card
              :border-variant="subscriptionId == plan.price_id ? 'success' : ''"
              align="center"
            >
              <h3>{{ plan.name }}</h3>

              <!-- annual plan -->
              <div class="annual-plan">
                <div class="h4 mt-2">
                  <span class="font-weight-bolder text-primary">{{ plan.price }}</span>
                </div>
              </div>
              <!--/ annual plan -->

              <!-- buttons -->
              <b-button
                v-if="subscriptionId == plan.price_id"
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                block
                class="mt-2"
                variant="outline-success"
                disabled
              >
                Your current plan
              </b-button>
              <b-button
                v-else
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                block
                class="mt-2"
                variant="primary"
                :disabled="isSubmitting"
                @click="selectPlanClicked(plan)"
              >
                {{ selectPlanButtonLabel }}
              </b-button>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
      <b-col
        v-if="subscriptionId"
        md="4"
      >
        <b-row />
        <b-row v-if="user && !user.trial_ends_at">
          <b-col md="12">
            <div class="h3 mb-2">
              Cancel anytime.
            </div>
          </b-col>

          <b-col md="12">
            Your cancellation will be complete at the end of the current billing cycle.

            <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              block
              class="mt-2"
              variant="outline-danger"
              @click="cancelPlanClicked()"
            >
              Cancel your current plan
            </b-button>
          </b-col>
        </b-row>
        <b-row v-else>
          <b-col md="12">
            <div class="h3 mb-2">
              {{ daysOfTrialLeft }}
            </div>
          </b-col>
          <b-col md="12">
            <div class="mb-2">
              No matter which plan you choose, you can cancel any time, risk-free.
            </div>
          </b-col>

          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            block
            class="mt-2"
            variant="outline-danger"
            @click="cancelPlanClicked()"
          >
            Cancel Trial & Close Account
          </b-button>

        </b-row>
      </b-col>
    </b-row>
  </div>

</template>
<script>
import {
  BButton,
  BCard,
  BCol,
  BRow,
} from 'bootstrap-vue'

import store from '@/store'
import Ripple from 'vue-ripple-directive'
import AccountService from '@/services/AccountService'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getNumberOfDaysLeft } from '@/utils/helpers'

export default {
  components: {
    BButton,
    BCol,
    BRow,
    BCard,
  },
  directives: {
    Ripple,
  },
  data() {
    const user = store.getters['auth/authUser']
    const data = {
      isSubmitting: false,
      subscriptionId: null,
      plans: null,
      selectPlanButtonLabel: '',
      user: null,
      daysOfTrialLeft: getNumberOfDaysLeft(user),
    }

    if ((user.subscription && !user.is_subscription_selection_required) || user.is_trial_used) {
      data.subscriptionId = user.subscription.plan_id
      data.selectPlanButtonLabel = 'Select plan'
    } else {
      data.selectPlanButtonLabel = 'Try it Free!'
    }

    if (user.subscription && user.subscription.status === 'canceled') {
      data.subscriptionId = null
    }

    data.user = user
    return data
  },
  created() {
    store.dispatch('auth/getAuthUser').then(() => {
      AccountService.getPlans()
        .then(response => {
          this.plans = response.data.data
        })
    })
  },
  methods: {
    showPlanSelectionConfirmationModal() {
      return this.$bvModal
        .msgBoxConfirm('Are you sure you want to select this plan?', {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
    },
    showCancelPlanConfirmationModal() {
      let message
      if (this.user.trial_ends_at) {
        message = 'Are you sure you want to end trial and close the account?'
      } else {
        message = 'Are you sure you want to cancel your plan? You won\'t be able to use visitplanner until you select a new one.'
      }

      return this.$bvModal
        .msgBoxConfirm(message, {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
    },
    async setPlan(plan) {
      this.isSubmitting = true
      try {
        const apiResponse = await AccountService.setPlan(plan.id)
        if (apiResponse.data.success === true) {
          await store.dispatch('auth/setAuthUser', apiResponse.data.data)
          this.subscriptionId = store.getters['auth/authUser'].subscription.plan_id

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'BellIcon',
              text: 'Your plan has changed!',
              variant: 'success',
            },
          })

          await this.$router.push({ name: 'home' })
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.isSubmitting = false
      }
    },
    async cancelPlan() {
      this.isSubmitting = true
      try {
        const apiResponse = await AccountService.cancelPlan()
        if (apiResponse.data.success === true) {
          if (apiResponse.data.account_deleted) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Notification',
                icon: 'BellIcon',
                text: 'Your plan has been cancelled and account was deleted.',
                variant: 'success',
              },
            })

            this.$store.dispatch('auth/logout')
            return
          }

          await store.dispatch('auth/setAuthUser', apiResponse.data.data)
          this.subscriptionId = null

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notification',
              icon: 'BellIcon',
              text: 'Your plan has been cancelled.',
              variant: 'success',
            },
          })

          // let's reload the whole SPA here
          window.location.reload()
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.isSubmitting = false
      }
    },

    async selectPlanClicked(plan) {
      const showConfirmationModal = this.showPlanSelectionConfirmationModal()
      showConfirmationModal.then(value => {
        if (value) {
          this.setPlan(plan)
        }
      })
    },
    async cancelPlanClicked() {
      const showConfirmationModal = this.showCancelPlanConfirmationModal()
      showConfirmationModal.then(value => {
        if (value) {
          this.cancelPlan()
        }
      })
    },

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-pricing.scss';
</style>
